/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.site-header-1').affix({
          offset: {
            top: $('.site-header-1').parent().offset().top
          }
        });

        $('.project-col').each(function(i) {
          $(this).waypoint(function(direction) {
            if (direction === 'down') { // hit the top of your element when scrolling down
              $(this.element).addClass('is-inview');
            }
          }, {
            offset: '65%'
          });
        });

        $('.team-col').each(function(i) {
          $(this).waypoint(function(direction) {
            if (direction === 'down') { // hit the top of your element when scrolling down
              $(this.element).addClass('is-inview');
            }
          }, {
            offset: '50%'
          });
        });

        $('.w-animate').each(function(i) {
          $(this).waypoint(function(direction) {
            if (direction === 'down') { // hit the top of your element when scrolling down
              $(this.element).addClass('w-animated');
            }
          }, {
            offset: '65%'
          });
        });

        $('.page.our-story .navbar-subnav a[href*="#"]:not([href="#"])').click(function() {
          if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
              $('html, body').animate({
                scrollTop: target.offset().top - 70
              }, 1000);
              return false;
            }
          }
        });

        $('.slideshow').slick({
          dots: false,
          infinite: true,
          pauseOnHover: false,
          speed: 2000,
          fade: true,
          cssEase: 'linear',
          autoplay: true,
          autoplaySpeed: 6000,
          arrows: false
        });

        $('.matchheight').matchHeight();
        var $grid = $('.project-grid').isotope({
          // options
          itemSelector: '.project-col',
          layoutMode: 'fitRows'
        });

        $grid.imagesLoaded().progress( function() {
          $grid.isotope('layout');
          $.fn.matchHeight._update();
        });

        $grid.on( 'layoutComplete', function() {
          $.fn.matchHeight._update();
        });

        $grid.one( 'layoutComplete', function() {
          if( $('body').is('[class*="term-"]') ) {
            var theClass = $('body').attr("class").match(/term-[\w-]*\b/);
            var term     = theClass[0].split('-');
            $('.filter-button-group').find('a[data-filter=".' + term[1] + '"]').trigger('click');
          }
        });

        $('.filter-button-group').on( 'click', 'a', function(e) {
          e.preventDefault();
          var filterValue = $(this).attr('data-filter');
          $grid.isotope({ filter: filterValue });
          $(this).parents('ul').find('.active').removeClass('active');
          $(this).parent().addClass('active');
          $('.project-grid').find('.project-col').addClass('is-inview');
        });

        $('.project-grid .project-content').on('click', function(e) {
          e.preventDefault();
          window.location = $(this).find('a').attr('href');
        });

        $("#navbar-primary-2").on("show.bs.collapse", function() {
          $('body').addClass('collapse-active');
          $('.site-header-3.navbar-default').addClass('navbar-inverse').removeClass('navbar-default');
        });
        $("#navbar-primary-2").on("hide.bs.collapse", function() {
          $('body').removeClass('collapse-active');
          $('.site-header-3.navbar-inverse').addClass('navbar-default').removeClass('navbar-inverse');
        });

        $("#navbar-primary-1").on("show.bs.collapse", function() {
          $('body').addClass('collapse-active');
          $('.site-header-2.navbar-default').addClass('navbar-inverse').removeClass('navbar-default');
        });
        $("#navbar-primary-1").on("hide.bs.collapse", function() {
          $('body').removeClass('collapse-active');
          $('.site-header-2.navbar-inverse').addClass('navbar-default').removeClass('navbar-inverse');
        });

        $("#navbar-primary").on("show.bs.collapse", function() {
          $('body').addClass('collapse-active');
          $('.site-header-1.navbar-default').addClass('navbar-inverse').removeClass('navbar-default');
        });
        $("#navbar-primary").on("hide.bs.collapse", function() {
          $('body').removeClass('collapse-active');
          $('.site-header-1.navbar-inverse').addClass('navbar-default').removeClass('navbar-inverse');
        });

        $('.slick-slider').not('.slick-initialized').slick({
          centerMode: true,
          arrows: true,
          slidesToShow: 1,
          dots: false,
          slidesToScroll: 1,
          variableWidth: true,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 4000
        });

        $('.slick-slider a').magnificPopup({
          type: 'image',
          gallery: {
            enabled: true
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        $('.hero-section').on('click', function(e) {
          e.preventDefault();
          e.stopPropagation();
          $('html, body').animate({
            scrollTop: $('.site-header-3').offset().top
          }, 1000);
        });

        $(window).on('scroll', function() {
          scrollPosition = $(this).scrollTop();
          if (scrollPosition >= 100 && $('body').hasClass('collapse-active')) {
            $("#navbar-primary-1").collapse("hide");
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
